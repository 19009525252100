:root {
  --black: #000000;
  --white: #ffffff;
  --off-white: #f8f6f3;
  --fuschia: #f988da;
  --purple: #a192ff;
  --blue: #6aa4fa;
  --sky: #bfe8ff;
  --teal: #4ec5d4;
  --green: #6eb987;
  --lime: #eaf447;
  --yellow: #ffe600;
  --sand: #f5bb55;
  --coral: #ff7c54;
}

.background-coral {
  background-color: var(--coral);
}

.background-sand {
  background-color: var(--sand);
}

.background-yellow {
  background-color: var(--yellow);
}

.background-lime {
  background-color: var(--lime);
}

.background-green {
  background-color: var(--green);
}

.background-teal {
  background-color: var(--teal);
}

.background-sky {
  background-color: var(--sky);
}

.background-blue {
  background-color: var(--blue);
}

.background-purple {
  background-color: var(--purple);
}

.background-fuschia {
  background-color: var(--fuschia);
}

pre {
  margin: 0;
}
#blocklyDiv {
  width: 100%;
  height: 100vh;
  transition: filter 100ms ease-in;
}
.selected svg {
  fill: white;
}
#blocklyDiv * {
  font-family: "Inter", sans-serif;
}
.blocklySvg {
  /* background-color: rgb(228, 199, 194); */

  background-image: url("/tiles/Scatter Pink.png");
  background-size: 80px;
  /* filter: brightness(1.1); */
  /*background-color: rgb(228, 199, 194, 0);*/
}

#world {
  position: relative;
  height: 100vh;
  flex-shrink: 0;
  background-image: url("/tiles/ScatterGray.png");
  background-size: 30px;
  opacity: 100%;
  transition: opacity 1s;
}

#world.loading {
  opacity: 0%;
}

@media only screen and (max-width: 700px) {
  #world {
    height: auto;
    width: 100vw !important;
  }
}

.blocklyMainBackground {
  stroke: none;
  /* fill: transparent !important; */
  /*pointer-events: none;*/
}

.blocklyBlockCanvas {
  filter: drop-shadow(1px 4px 4px rgba(46, 44, 44, 0.228));
}
.App {
  display: flex;
  /* transition: filter 10ms linear; */
}

.resizeHandle {
  width: 8px;
  height: 100%;
  /* border-radius: 6px 0 0 6px; */
  /* background-color: gainsboro; */
  background: repeating-linear-gradient(
    90deg,
    gray,
    gray 1px,
    gray 2px,
    gainsboro 2px,
    gainsboro 3px
  );
  /* box-shadow: 0px 0px 3px rgba(0,0,0,.3); */
  border: 0px ridge gray;
  position: absolute;
  left: -8px;
  top: 0;
  z-index: 2000;
  cursor: ew-resize;
}

label {
  color: black;
  font-size: 16px;
}
#fps {
  position: fixed;
  display: block;
  align-content: right;
  text-align: right;
  width: 100px;
  right: 5px;
  bottom: 40px;
  z-index: 2000;
  color: darkslateblue;
  pointer-events: none;
}

.Dropdown-control {
  padding-right: 30px !important;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.217);
  border-radius: 0;
  border: 1px solid gray;
}
