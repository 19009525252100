button,
input {
  height: 35px;
  min-width: 30px;
  font-size: 19px;
  padding: 5px 7px;
  background-color: whitesmoke;
  color: black;
  border: 2px solid whitesmoke;
  border-color: gainsboro;
  flex-grow: 1;
  fill: black;
  user-select: none;
  line-height: 0px;
  white-space: nowrap;
  vertical-align: middle;
}

input[type="checkbox"] {
  box-sizing: border-box;
  margin-left: 0;
  border: 2px solid whitesmoke;
  border-color: gainsboro;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

label {
  /* background-color: whitesmoke; */
  color: black;
  /* border: 2px solid whitesmoke; */
  /* border-color: gainsboro; */
  border-right: none;
  height: 35px;
  display: inline-block;
  padding: 5px 7px;
  margin-right: 0;
  vertical-align: middle;
  line-height: 25px;
  font-family: "Inter", sans-serif;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.size-button {
  height: 35px;
  width: 35px;
  line-height: 0px;
}
button {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  /* margin-bottom: -2px; */
}
.simulation-button {
  z-index: 1;
  width: fit-content;
}
.play-pause-button {
  min-width: 88px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.play-pause-button svg {
  margin-bottom: 2px;
  margin-right: 2px;
}

.element-tray button.element-control {
  min-width: 35px;
  height: 35px;

  flex-grow: 0;
  font-size: 20px;
}
#worldCanvas {
  z-index: 1;
  width: 100%;
  border: 3px ridge gray;
  /* border-left: 1px solid black; */
  touch-action: pinch-zoom;
}
.loading-curtain-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.loading-curtain {
  position: absolute;
  z-index: 9999;
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(220, 220, 220, 1);
  /* backdrop-filter: blur(15px); */
  background-image: url("/tiles/ScatterGray.png");
  background-size: 100px;
  text-align: center;
  color: var(--black);
  font-size: 24px;
  transition: transform 0.5s ease-in;
}
.loading-curtain.loading-curtain-top {
  border-bottom: 1px solid rgb(148, 148, 148);
  top: 0;
  transform: translateY(-100%);
}
.loading-curtain.loading-curtain-bottom {
  border-top: 2px solid rgb(148, 148, 148);

  justify-content: flex-start;
  transform: translateY(100%);

  bottom: 0;
}

.loading-curtain-top.visible {
  transition: transform 0.25s ease-out;
  transform: translateY(0);
}
.loading-curtain-bottom.visible {
  transition: transform 0.25s ease-out;
  transform: translateY(0);
}

@media only screen and (max-width: 700px) {
  .loading-curtain.loading-curtain-top {
    transform: translateX(-100%);
  }
  .loading-curtain.loading-curtain-bottom {
    border-top: 2px solid rgb(148, 148, 148);

    justify-content: flex-start;
    transform: translateX(100%);
  }

  .loading-curtain-top.visible {
    transition: transform 0.25s ease-out;
    transform: translateX(0);
  }
  .loading-curtain-bottom.visible {
    transition: transform 0.25s ease-out;
    transform: translateX(0);
  }
}

.element-tray button {
  min-width: 80px;
}
.shrink {
  transform: scale(0.8);
  filter: saturate(150%);
  transition: all 50ms linear;
  border-color: red !important;
}
.spacer {
  flex-grow: 10;
  width: auto;
}

.element-tray {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 2px;
}

.update-scheme-tray {
  display: flex;
}
.extras-tray {
  display: flex;
  padding: 5px;
  flex-direction: column;
}
.extras-tray .Auth {
  position: fixed;
  right: 5px;
  bottom: 60px;
}

.extras-tray a {
  font-weight: bold;
  cursor: pointer;
}

.extras-tray a:hover {
  text-decoration: underline;
}

.controls-row {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 20px;
}

.controls-row span {
  display: flex;
}

.controls-row span:not(.sizes) {
  flex-wrap: wrap;
}

.controls-row * {
  flex-grow: 0;
}

.worldSizes {
  display: flex;
  flex-direction: column;
}

textarea#json {
  width: 100%;
  height: 60px;
}
summary {
  font-size: 1rem;
  color: black;
}
.wordmark {
  position: fixed;
  bottom: 1px;
  width: 180px;
  right: 0px;
  margin-bottom: 0;
  pointer-events: none;
  /* image-rendering: pixelated; */
  /* opacity: 0.5; */
}

@media only screen and (max-width: 1100px) {
  .wordmark {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .extras-tray .Auth {
    position: relative;
    float: right;
    bottom: 0px;
  }
}

.browse-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2px;
  /*max-width: 400px;*/
  /*max-width: calc(50vw - 5px);*/
}

.element-set-button {
  flex-grow: 0;
  height: auto;
  text-align: start;
  padding: 0px;
}

button.selected {
  /* border-color: white; */
  background: black;
  color: white;
  /* border-radius: 6px; */
  text-shadow: 1px 0px rgba(0, 0, 0, 0.5), -1px 0px rgba(0, 0, 0, 0.5),
    0px 1px rgba(0, 0, 0, 0.5), 0px -1px rgba(0, 0, 0, 0.5);
}
button:hover {
  filter: brightness(0.9);
}
