.browse-page {
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  justify-content: center;
  overflow-x: hidden;
}

/* Placeholder */
button.editor-toggle {
  background-color: #a192ff;
  opacity: 100% !important;
}

.browse {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px;
  /* max-width: 90vw; */
  height: 100%;
  overflow: scroll;
}
.browse.family {
  margin: 0;
  max-width: 700px;
  width: 100%;
  overflow: overlay;
  padding-right: 8px;
}
@media only screen and (max-width: 700px) {
  .browse-page {
    flex-direction: column-reverse;
    height: auto;
  }

  .browse {
    height: 85vh;
    overflow-y: hidden;
  }
  .browse.family {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 500px) {
  .family {
    margin: 0;
  }
}

.filterControls {
  display: flex;
  margin-bottom: 6px;
}
.filterControls .Dropdown-control {
  height: 35px;

  padding: 6px 6px;
  font-family: "Inter", sans-serif;
}
.nav-bar {
  display: flex;
  margin-bottom: 6px;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-bar-group {
  display: flex;
}

.replies-container {
  margin-top: 0px;
  margin-left: 5%;
  margin-bottom: 20px;
}

.parent-container {
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 0px;
}
