.family .title {
  overflow-y: scroll;
  max-height: 180px;
}

.featured-flag {
  margin-left: 5px;
}

.play-count {
  margin-left: 5px;
}

/* .family img {
  width: 100px;
  margin-right: 3px;
} */
.family img {
  border: 1px solid gainsboro;
}
.family img:hover {
  border: 1px solid;
}
.family .title-container {
  margin: 0px;

  font-size: 20px;
  /* font-weight: bold; */
  flex-basis: 50px;
  flex-grow: 1;

  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;

  /* text-align: center; */
}

@media only screen and (max-width: 700px) {
  .family .title-container {
    font-size: 20px;
  }
}

.browse-post-metadata-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1px;
}
.browse-post-metadata-row span {
  display: flex;
  align-items: flex-end;
}
.browse-post-metadata-row button {
  margin-right: 4px;
}
.family .info {
  position: absolute;
  bottom: 3px;
}

.family {
  margin-top: 1.5em;
  display: flex;
  /* flex-direction: column; */
}
.post-group {
  display: flex;
  position: relative;
}

.group-title {
  position: absolute;
  top: -1.2em;
}

.post {
  background-color: rgba(250, 247, 247, 0.723);
  padding: 5px;
  margin-bottom: 6px;
  position: relative;
  display: flex;
  border: 0px solid transparent;
  border-right: 0;
  cursor: pointer;
  overflow: hidden;
}

.post.splash {
  display: block;
  font-size: 19px;
  margin-top: 0px;
  cursor: unset;
  overflow:visible;
}

.post.splash p {
  margin: 5px 5px 10px 5px;
}

.post > .profile {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.selected {
  box-shadow: 0 0 0 1px black inset;
  background-color: rgba(250, 247, 247, 1);
}

.post:not(.splash):hover {
  background-color: rgba(250, 247, 247, 0.45);
}
.post.selected {
  cursor: unset;
  box-shadow: 8px 0px 0px 0px rgba(250, 247, 247, 1);
}
.post:hover.selected {
  box-shadow: 8px 0px 0px 0px rgba(250, 247, 247, 0.45);
  background-color: rgba(250, 247, 247, 1);
}

.post.placeholder {
  filter: grayscale(100%) contrast(0.5);
}
.postThumbnail {
  position: relative;
  margin-right: 3px;
}
.postThumbnail,
.postThumbnail img {
  width: calc(clamp(100px, 20vw - 6px - 10px, 300px));
  height: calc(clamp(100px, 20vw - 6px - 10px, 300px));
}

.userCard {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
}
.userCard a {
  margin-left: 10px;
  font-weight: 500;
}
.pfp {
  border-radius: 100%;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
}
.userCard.pfp:hover {
  border: 1px solid;
}
.userCard > a > .userName:hover  {
  text-decoration: underline;
}

.timestamp {
  font-size: 16px;
}

.post-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.replies-button {
  font-size: 16px;
  margin-right: -10px;
  margin-top: -10px;
  cursor: pointer;
  flex-grow: 0;
  width: 100px;
  text-align: right;
}

.browse-post-metadata-row .replies-button {
  margin-right: -10px;
  margin-bottom: -10px;
}

@media only screen and (max-width: 700px) {
  .replies-button {
    width: 35px;
    text-align: center;
  }
}

.explanation {
  display: none;
}
.publicPostToggle:hover .explanation {
  display: block;
}

.profile-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 10px 0px;
}
.profile-stats div {
  margin-right: 8px;
}

.post:not(.splash) button {
  font-size: 16px;
}
