@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;400&family=Inter&display=swap");
* {
  box-sizing: border-box;
  /* font-family: "Inconsolata", monospace !important; */
}

body {
  background-color: rgb(225, 222, 218);
  font-family: "Inconsolata", monospace;
  margin: 0;
  background-size: 70px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --off-white: #f8f6f3;
  --fuschia: #f988da;
  --purple: #a192ff;
  --blue: #6aa4fa;
  --sky: #bfe8ff;
  --teal: #4ec5d4;
  --green: #6eb987;
  --lime: #eaf447;
  --yellow: #ffe600;
  --sand: #f5bb55;
  --coral: #ff7c54;
}

.background-coral {
  background-color: var(--coral);
}

.background-sand {
  background-color: var(--sand);
}

.background-yellow {
  background-color: var(--yellow);
}

.background-lime {
  background-color: var(--lime);
}

.background-green {
  background-color: var(--green);
}

.background-teal {
  background-color: var(--teal);
}

.background-sky {
  background-color: var(--sky);
}

.background-blue {
  background-color: var(--blue);
}

.background-purple {
  background-color: var(--purple);
}

.background-fuschia {
  background-color: var(--fuschia);
}

pre {
  margin: 0;
}
#blocklyDiv {
  width: 100%;
  height: 100vh;
  transition: -webkit-filter 100ms ease-in;
  transition: filter 100ms ease-in;
  transition: filter 100ms ease-in, -webkit-filter 100ms ease-in;
}
.selected svg {
  fill: white;
}
#blocklyDiv * {
  font-family: "Inter", sans-serif;
}
.blocklySvg {
  /* background-color: rgb(228, 199, 194); */

  background-image: url("/tiles/Scatter Pink.png");
  background-size: 80px;
  /* filter: brightness(1.1); */
  /*background-color: rgb(228, 199, 194, 0);*/
}

#world {
  position: relative;
  height: 100vh;
  flex-shrink: 0;
  background-image: url("/tiles/ScatterGray.png");
  background-size: 30px;
  opacity: 100%;
  transition: opacity 1s;
}

#world.loading {
  opacity: 0%;
}

@media only screen and (max-width: 700px) {
  #world {
    height: auto;
    width: 100vw !important;
  }
}

.blocklyMainBackground {
  stroke: none;
  /* fill: transparent !important; */
  /*pointer-events: none;*/
}

.blocklyBlockCanvas {
  -webkit-filter: drop-shadow(1px 4px 4px rgba(46, 44, 44, 0.228));
          filter: drop-shadow(1px 4px 4px rgba(46, 44, 44, 0.228));
}
.App {
  display: flex;
  /* transition: filter 10ms linear; */
}

.resizeHandle {
  width: 8px;
  height: 100%;
  /* border-radius: 6px 0 0 6px; */
  /* background-color: gainsboro; */
  background: repeating-linear-gradient(
    90deg,
    gray,
    gray 1px,
    gray 2px,
    gainsboro 2px,
    gainsboro 3px
  );
  /* box-shadow: 0px 0px 3px rgba(0,0,0,.3); */
  border: 0px ridge gray;
  position: absolute;
  left: -8px;
  top: 0;
  z-index: 2000;
  cursor: ew-resize;
}

label {
  color: black;
  font-size: 16px;
}
#fps {
  position: fixed;
  display: block;
  align-content: right;
  text-align: right;
  width: 100px;
  right: 5px;
  bottom: 40px;
  z-index: 2000;
  color: darkslateblue;
  pointer-events: none;
}

.Dropdown-control {
  padding-right: 30px !important;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.217);
  border-radius: 0;
  border: 1px solid gray;
}

button,
input {
  height: 35px;
  min-width: 30px;
  font-size: 19px;
  padding: 5px 7px;
  background-color: whitesmoke;
  color: black;
  border: 2px solid whitesmoke;
  border-color: gainsboro;
  flex-grow: 1;
  fill: black;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 0px;
  white-space: nowrap;
  vertical-align: middle;
}

input[type="checkbox"] {
  box-sizing: border-box;
  margin-left: 0;
  border: 2px solid whitesmoke;
  border-color: gainsboro;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

label {
  /* background-color: whitesmoke; */
  color: black;
  /* border: 2px solid whitesmoke; */
  /* border-color: gainsboro; */
  border-right: none;
  height: 35px;
  display: inline-block;
  padding: 5px 7px;
  margin-right: 0;
  vertical-align: middle;
  line-height: 25px;
  font-family: "Inter", sans-serif;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.size-button {
  height: 35px;
  width: 35px;
  line-height: 0px;
}
button {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  /* margin-bottom: -2px; */
}
.simulation-button {
  z-index: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.play-pause-button {
  min-width: 88px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.play-pause-button svg {
  margin-bottom: 2px;
  margin-right: 2px;
}

.element-tray button.element-control {
  min-width: 35px;
  height: 35px;

  flex-grow: 0;
  font-size: 20px;
}
#worldCanvas {
  z-index: 1;
  width: 100%;
  border: 3px ridge gray;
  /* border-left: 1px solid black; */
  touch-action: pinch-zoom;
}
.loading-curtain-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.loading-curtain {
  position: absolute;
  z-index: 9999;
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(220, 220, 220, 1);
  /* backdrop-filter: blur(15px); */
  background-image: url("/tiles/ScatterGray.png");
  background-size: 100px;
  text-align: center;
  color: var(--black);
  font-size: 24px;
  transition: -webkit-transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
}
.loading-curtain.loading-curtain-top {
  border-bottom: 1px solid rgb(148, 148, 148);
  top: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.loading-curtain.loading-curtain-bottom {
  border-top: 2px solid rgb(148, 148, 148);

  justify-content: flex-start;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);

  bottom: 0;
}

.loading-curtain-top.visible {
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.loading-curtain-bottom.visible {
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

@media only screen and (max-width: 700px) {
  .loading-curtain.loading-curtain-top {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .loading-curtain.loading-curtain-bottom {
    border-top: 2px solid rgb(148, 148, 148);

    justify-content: flex-start;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  .loading-curtain-top.visible {
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .loading-curtain-bottom.visible {
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.element-tray button {
  min-width: 80px;
}
.shrink {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-filter: saturate(150%);
          filter: saturate(150%);
  transition: all 50ms linear;
  border-color: red !important;
}
.spacer {
  flex-grow: 10;
  width: auto;
}

.element-tray {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 2px;
}

.update-scheme-tray {
  display: flex;
}
.extras-tray {
  display: flex;
  padding: 5px;
  flex-direction: column;
}
.extras-tray .Auth {
  position: fixed;
  right: 5px;
  bottom: 60px;
}

.extras-tray a {
  font-weight: bold;
  cursor: pointer;
}

.extras-tray a:hover {
  text-decoration: underline;
}

.controls-row {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 20px;
}

.controls-row span {
  display: flex;
}

.controls-row span:not(.sizes) {
  flex-wrap: wrap;
}

.controls-row * {
  flex-grow: 0;
}

.worldSizes {
  display: flex;
  flex-direction: column;
}

textarea#json {
  width: 100%;
  height: 60px;
}
summary {
  font-size: 1rem;
  color: black;
}
.wordmark {
  position: fixed;
  bottom: 1px;
  width: 180px;
  right: 0px;
  margin-bottom: 0;
  pointer-events: none;
  /* image-rendering: pixelated; */
  /* opacity: 0.5; */
}

@media only screen and (max-width: 1100px) {
  .wordmark {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .extras-tray .Auth {
    position: relative;
    float: right;
    bottom: 0px;
  }
}

.browse-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2px;
  /*max-width: 400px;*/
  /*max-width: calc(50vw - 5px);*/
}

.element-set-button {
  flex-grow: 0;
  height: auto;
  text-align: start;
  padding: 0px;
}

button.selected {
  /* border-color: white; */
  background: black;
  color: white;
  /* border-radius: 6px; */
  text-shadow: 1px 0px rgba(0, 0, 0, 0.5), -1px 0px rgba(0, 0, 0, 0.5),
    0px 1px rgba(0, 0, 0, 0.5), 0px -1px rgba(0, 0, 0, 0.5);
}
button:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.family .title {
  overflow-y: scroll;
  max-height: 180px;
}

.featured-flag {
  margin-left: 5px;
}

.play-count {
  margin-left: 5px;
}

/* .family img {
  width: 100px;
  margin-right: 3px;
} */
.family img {
  border: 1px solid gainsboro;
}
.family img:hover {
  border: 1px solid;
}
.family .title-container {
  margin: 0px;

  font-size: 20px;
  /* font-weight: bold; */
  flex-basis: 50px;
  flex-grow: 1;

  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;

  /* text-align: center; */
}

@media only screen and (max-width: 700px) {
  .family .title-container {
    font-size: 20px;
  }
}

.browse-post-metadata-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1px;
}
.browse-post-metadata-row span {
  display: flex;
  align-items: flex-end;
}
.browse-post-metadata-row button {
  margin-right: 4px;
}
.family .info {
  position: absolute;
  bottom: 3px;
}

.family {
  margin-top: 1.5em;
  display: flex;
  /* flex-direction: column; */
}
.post-group {
  display: flex;
  position: relative;
}

.group-title {
  position: absolute;
  top: -1.2em;
}

.post {
  background-color: rgba(250, 247, 247, 0.723);
  padding: 5px;
  margin-bottom: 6px;
  position: relative;
  display: flex;
  border: 0px solid transparent;
  border-right: 0;
  cursor: pointer;
  overflow: hidden;
}

.post.splash {
  display: block;
  font-size: 19px;
  margin-top: 0px;
  cursor: unset;
  overflow:visible;
}

.post.splash p {
  margin: 5px 5px 10px 5px;
}

.post > .profile {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.selected {
  box-shadow: 0 0 0 1px black inset;
  background-color: rgba(250, 247, 247, 1);
}

.post:not(.splash):hover {
  background-color: rgba(250, 247, 247, 0.45);
}
.post.selected {
  cursor: unset;
  box-shadow: 8px 0px 0px 0px rgba(250, 247, 247, 1);
}
.post:hover.selected {
  box-shadow: 8px 0px 0px 0px rgba(250, 247, 247, 0.45);
  background-color: rgba(250, 247, 247, 1);
}

.post.placeholder {
  -webkit-filter: grayscale(100%) contrast(0.5);
          filter: grayscale(100%) contrast(0.5);
}
.postThumbnail {
  position: relative;
  margin-right: 3px;
}
.postThumbnail,
.postThumbnail img {
  width: calc(clamp(100px, 20vw - 6px - 10px, 300px));
  height: calc(clamp(100px, 20vw - 6px - 10px, 300px));
}

.userCard {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
}
.userCard a {
  margin-left: 10px;
  font-weight: 500;
}
.pfp {
  border-radius: 100%;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
}
.userCard.pfp:hover {
  border: 1px solid;
}
.userCard > a > .userName:hover  {
  text-decoration: underline;
}

.timestamp {
  font-size: 16px;
}

.post-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.replies-button {
  font-size: 16px;
  margin-right: -10px;
  margin-top: -10px;
  cursor: pointer;
  flex-grow: 0;
  width: 100px;
  text-align: right;
}

.browse-post-metadata-row .replies-button {
  margin-right: -10px;
  margin-bottom: -10px;
}

@media only screen and (max-width: 700px) {
  .replies-button {
    width: 35px;
    text-align: center;
  }
}

.explanation {
  display: none;
}
.publicPostToggle:hover .explanation {
  display: block;
}

.profile-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 10px 0px;
}
.profile-stats div {
  margin-right: 8px;
}

.post:not(.splash) button {
  font-size: 16px;
}

.StarButton {
  line-height: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  position: relative;
  cursor: pointer;
}

.StarButton .StarAnimation {
  position: absolute;
  top: -32px;
  left: -32px;
  pointer-events: none;
}

.StarButton .StarIcon {
  position: relative;
  transition: -webkit-transform 100ms;
  transition: transform 100ms;
  transition: transform 100ms, -webkit-transform 100ms;
  stroke: #ababab;
}
.StarButton:active .StarIcon {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.StarButton--starred .StarIcon,
.StarButton--starred:hover .StarIcon {
  -webkit-animation: 500ms star-grow-rotate ease-out;
          animation: 500ms star-grow-rotate ease-out;
  fill: #f7d527;
  stroke: #ababab;
}

@-webkit-keyframes star-grow-rotate {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }

  50% {
    -webkit-transform: scale(1.5) rotate(120deg);
            transform: scale(1.5) rotate(120deg);
  }

  100% {
    /* 72deg is a rotation by one spike. The star has 5 spikes. 360deg / 5 = 72 */
    -webkit-transform: scale(1) rotate(144deg);
            transform: scale(1) rotate(144deg);
  }
}

@keyframes star-grow-rotate {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }

  50% {
    -webkit-transform: scale(1.5) rotate(120deg);
            transform: scale(1.5) rotate(120deg);
  }

  100% {
    /* 72deg is a rotation by one spike. The star has 5 spikes. 360deg / 5 = 72 */
    -webkit-transform: scale(1) rotate(144deg);
            transform: scale(1) rotate(144deg);
  }
}

.browse-page {
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  justify-content: center;
  overflow-x: hidden;
}

/* Placeholder */
button.editor-toggle {
  background-color: #a192ff;
  opacity: 100% !important;
}

.browse {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px;
  /* max-width: 90vw; */
  height: 100%;
  overflow: scroll;
}
.browse.family {
  margin: 0;
  max-width: 700px;
  width: 100%;
  overflow: overlay;
  padding-right: 8px;
}
@media only screen and (max-width: 700px) {
  .browse-page {
    flex-direction: column-reverse;
    height: auto;
  }

  .browse {
    height: 85vh;
    overflow-y: hidden;
  }
  .browse.family {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 500px) {
  .family {
    margin: 0;
  }
}

.filterControls {
  display: flex;
  margin-bottom: 6px;
}
.filterControls .Dropdown-control {
  height: 35px;

  padding: 6px 6px;
  font-family: "Inter", sans-serif;
}
.nav-bar {
  display: flex;
  margin-bottom: 6px;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-bar-group {
  display: flex;
}

.replies-container {
  margin-top: 0px;
  margin-left: 5%;
  margin-bottom: 20px;
}

.parent-container {
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 0px;
}

#blocklyDiv {
  /*width: 100%;
  position: absolute;
  bottom: 0;
  top:310px;*/
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media only screen and (max-width: 700px) {
  #blocklyDiv {
    display: none;
  }
}

.blocklyFlyout {
  height: 200vh;
  /* transform: translateX(-10%) !important; */
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: -webkit-transform 40ms ease-in-out;
  transition: transform 40ms ease-in-out;
  transition: transform 40ms ease-in-out, -webkit-transform 40ms ease-in-out;
  background-color: rgba(225, 222, 218, 0.632);

  /* background: linear-gradient(-90deg, rgb(178, 172, 172), transparent); */
  /* box-shadow: 2px 2px 4px rgba(141, 125, 125, 0.325); */
  border-right: 2px solid rgba(141, 125, 125, 0.725);
}
.blocklyFlyoutBackground {
  fill: transparent;
}

.blocklyFlyout:hover {
  /* transform: translateX(0%) !important; */
}

.blocklyFlyoutScrollbar {
  display: none;
}
.blocklyFlyoutBackground {
  -webkit-transform: scaleY(2) !important;
          transform: scaleY(2) !important;
}

